import React, {Suspense} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import {observer} from "mobx-react";

import Loading from "app/pages/Loading.jsx";
import {auth, system} from "store";
import httpErrors from "usecase/errors.js";
import {queryParams} from "./utils";

const Cabinet = React.lazy(() => import("app/layout/Cabinet.jsx"));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      serverError: false
    };
  }

  async componentDidMount() {
    try {
      await auth.update();
      if (auth.authenticated) {
        await system.load();
      }
      this.setState({loading: false});

      setInterval(async () => {
        if (auth.authenticated) {
          try {
            await auth.update();
            if (this.state.serverError) {
              this.setState({serverError: false});
            }
          } catch (e) {
            if (e instanceof httpErrors.ServerError) {
              this.setState({serverError: true});
            } else {
              throw e;
            }
          }
        }
      }, 3000);
    } catch (e) {
      if (e instanceof httpErrors.ServerError) {
        this.setState({serverError: true});
      } else {
        throw e;
      }
    }
  }

  render() {
    if (this.state.serverError && !auth.authenticated) {
      return "Internal Server Error. Sorry, something went wrong.";
    } else if (this.state.loading) {
      return <Loading />;
    } else if (!auth.authenticated) {
      let params = {
        continue: window.location.href
      };
      if (auth.sessionExpired) {
        params["code"] = 1;
      }
      window.location.assign(process.env.REACT_APP_PROFILE_URL + "/login?" + queryParams(params));
      return null;
    } else {
      return (
        <Router>
          <Suspense fallback={<Loading />}>
            <Cabinet />
          </Suspense>
        </Router>
      );
    }
  }
}

export default observer(App);
