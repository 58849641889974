import {decorate, action} from "mobx";

import brands from "store/brands.js";
import profile from "store/profile.js";
import settings from "store/settings.js";

class System {
  async load() {
    return await Promise.all([profile.update(), settings.update(), brands.update()]);
  }
}

System = decorate(System, {
  load: action
});

export default new System();
